
import Http from './Http'

export default {
    async getMatches() {
        return await Http.get(`/football/matches`)
    },
    async createMatchPreviewText(match_id, data) {
        return await Http.post(`/football/match/${match_id}/previewText`, data)
    },
    async getSportTeams(data) {
        return await Http.get(`/football/teams?${data}`)
    },
    async updateSportTeam(id, data) {
        return await Http.patch(`/football/sport_team/${id}`, data)
    },

}


