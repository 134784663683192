<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import Football from "@/services/Football";
    import ModalEditSportTeam from "@/components/modals/monitor/modalEditSportTeam";
    import Swal from "sweetalert2";

    export default {
        components: {
            Layout,
            PageHeader,
            ModalEditSportTeam
      },
        page: {
            title: "Sport Team",
        meta: [
          {
              name: "description",
            content: appConfig.description,
          },
        ],
      },
        data() {
        return {
            error: null,
          tableData: [],
          tableDataTournament: [],
          title: "Sport Team",
          items: [
            {
                text: "Sport Data",
            },
            {
              text: "Sport Team",
              active: true,
            },
          ],
          isBusy: false,
          totalRows: 0,
          totalRowsTournament: 0,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          filter: null,
          filterOn: [],
          sortBy: "valid_from",
          sortDesc: true,
          dealData: [],
          teamsData: [],
          sportTeamData: [],
          fields: [
            {
                key: "team_id",
              label: "Team Id ",
              visible: false,
            },
            {
              key: "team_name_new",
              label: "Team name",
              visible: true,
            },
            {
              key: "team_name",
              label: "Team name overwrite",
              visible: true,
            },
            {
              key: "team_code_new",
              label: "Team code",
              visible: true,
            },
            {
              key: "team_name_short",
              label: "Team code overwrite",
              visible: true,
            },
            {
              key: "team_name_official",
              label: "Team name official",
              visible: true,
            },
            {
              key: "team_type",
              label: "Team Type",
              visible: true,
            },
            {
              key: "team_country_code",
              label: "Team country code",
              visible: true,
            },
            {
              key: "team_logo",
              label: "Team logo",
              visible: true,
            },
            {
               key: "provider",
               label: "Provider",
               visible: true,
            },
            {
                key: "Action",
              label: "Action",
            }
          ],
          statusOptions: [],
          isBusyAll: false,
          totalRowsAll: 0,
          currentPageAll: 1,
          perPageAll: 10,
          pageOptionsAll: [10, 25, 50, 100],
          filterAll: null,
          filterOnAll: [],
          filterData: false,
          loading: false ,
            provider: [] ,
            countryCode : []  ,
            filterInput: {
                provider: '',
                countryCode : '' ,
            },
        };
      },
        computed: {
            providerOptions() {
                return this.provider.map(p => ({ text: p, value: p }));
            } ,
            countryCodeOptions() {
                return this.countryCode.map(p => ({ text: p, value: p }));
            }
        },
      async created() {
        await this.getSportTeams1();
      },
      methods: {
            onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.totalRowsTournament = filteredItems.length;
            this.currentPage = 1;
        },

          getFilters(){
              let filter_string = 'team_sport=football';
              if (this.filterInput.provider) {
                  filter_string += `&provider=${this.filterInput.provider}`;
              }
              if (this.filterInput.countryCode) {
                  filter_string += `&team_country_code=${this.filterInput.countryCode}`;
              }
              return filter_string;
          } ,


          async getSportTeams() {
          try {
              this.filterData = true;
              this.isBusy = true;
              var filters = this.getFilters()
              const response = await Football.getSportTeams(filters);
            this.tableData = response.data.teams;
            this.teamsData = response.data.teams.map(item => item.teams);
            this.totalRows = this.tableData.length
            this.isBusy = false;
          } catch (error) {
              this.isBusy = false;
              this.tableData = []
            this.totalRows = 0
          }
        },

          async getSportTeams1() {
              try {
                  var filters = 'team_sport=football';
                  const response1 = await Football.getSportTeams(filters);

                  const teams = response1.data.teams;
                  const countryCodes = teams.map(team => team.team_country_code);
                  this.countryCode = [...new Set(countryCodes)].sort();

                  const providers = teams.map(team => team.provider);
                  this.provider = [...new Set(providers)];
              } catch (error) {
                  console.log('');
              }
          },


          resetFilters() {
              this.filterInput = {
                  provider: ''
              };
              this.getSportTeams();
          },

          callModalEditSportTeam(data) {

        this.$bvModal.show("edit_sport_team");
          this.sportTeamData = data
        },
      },
      middleware: "authentication",
    };
    </script>

    <template>
      <Layout>
        <PageHeader :title="title" :items="items" :fields="fields"/>
        <div class="row">
          <div class="col-12">

              <div class="col-md-12">
                  <div class="custom-accordion">
                      <a
                              class="text-body fw-semibold pb-2 d-block"
                              data-toggle="collapse"
                              href="javascript: void(0);"
                              role="button"
                              aria-expanded="false"
                              v-b-toggle.categories-collapse
                      >
                          <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                      </a>
                      <b-collapse visible id="categories-collapse">
                          <div class="card p-2 border shadow-none">
                              <div class="row">
                                  <div class="col-sm-12 col-md-3">
                                      <label class="control-label form-label">Providers</label>
                                      <b-form-select
                                              v-model="filterInput.provider"
                                              :options="providerOptions"
                                              aria-describedby="status-feedback"
                                      ></b-form-select>
                                  </div>
                                  <div class="col-sm-12 col-md-3">
                                      <label class="control-label form-label">Country Code</label>
                                      <b-form-select
                                              v-model="filterInput.countryCode"
                                              :options="countryCodeOptions"
                                              aria-describedby="status-feedback"
                                      ></b-form-select>
                                  </div>
                              </div>
                              <div class="row mt-3">
                                  <div class="col d-inline-flex">
                                      <button type="button" class="btn btn-success me-2 w-lg" @click="getSportTeams">Filter</button>
                                      <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                  </div>
                              </div>

                          </div>
                      </b-collapse>
                  </div>
              </div>





              <div class="card" v-show="filterData">
              <div class="card-body">
                <div class="row mt-4">
                  <div class="col d-inline-flex align-items-center">
                    <h4 class="card-title m-0">Total Sport Teams: {{ totalRows }}</h4>
                  </div>
                </div>
                <div class="row mt-4" v-show="filterData">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
    Show&nbsp;
                        <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
        ></b-form-select
    >&nbsp;entries
    </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="deals-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
    Search:
                        <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
    ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <!-- Table -->
                <div class="table-responsive mb-0 font-size-12" v-show="filterData">
                  <b-table
                      :items="tableData"
                      :busy="isBusy"
                      :fields="fields"
                      :visible="fields.visible"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                          empty-text="No Data Found"
                              >
                    <template v-slot:cell(action)="data">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Sport Team" @click="callModalEditSportTeam(data.item)">
                            <i class="uil uil-pen font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                    <template #table-busy>
    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>
                </div>
                <!--Table end -->
                <div class="row" v-show="filterData">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                         ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- MODALS -->
        <ModalEditSportTeam :sportTeamData="sportTeamData" @onRefresh="getSportTeams()"></ModalEditSportTeam>
        <!-- /MODALS -->
      </Layout>
    </template>

